import { Fragment, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { Box, Divider, HStack, ScrollView, Spacer, useMediaQuery, useTheme } from 'native-base';
import Octicons from 'react-native-vector-icons/Octicons';
import { isEmpty, map } from 'lodash';

import { Modal, PlusCircleIcon, Text } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { usePositioningPlan } from '../context';
import { PositioningSlot } from '../reducers';
import { PositioningSelectEmployee } from './positioning-select-employee';
import { PositioningSelectPosition } from './positioning-select-position';
import { PositioningPlanNonServices } from './positioning-plan-nonservices';
import DroppablePositionSlot from './droppable-position-slot';
import { PopoverSelectOpsLeader } from './popover-select-ops-leader';
import { PopoverTooltipPositionActions } from './popover-tooltip-position-actions';

type PositioningPlanSlotsProps = {
  isDisabled?: boolean;
};

export const PositioningPlanSlots = ({ isDisabled }: PositioningPlanSlotsProps) => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery([{ maxWidth: 1400 }]);
  const { positioning, deleteAssignee, replaceAssignee, updatePositionSlot, moveTo } = usePositioningPlan();
  const { positionLookup } = useKitchenLayout();
  const modalSelect = useModalFocus<PositioningSlot>();
  const modalPosition = useModalFocus<{ isPrimary?: boolean; positionSlotId: string }>();
  const [layout, setLayout] = useState<Pick<LayoutRectangle, 'width' | 'height'>>({ width: 0, height: 0 });

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayout(nativeEvent.layout);
  };

  return (
    <Fragment>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} h="full" w="full" bg="gray.50" onLayout={handleChangeLayout}>
        <HStack px={1}>
          <Box flex={1} py={1.5} pr={1}>
            <HStack flexWrap="wrap">
              {!isEmpty(positioning?.positionSlots) && (
                <HStack space={1.5} alignItems="center" px={2} py={1} minH={12} w="full">
                  <PlusCircleIcon size={6} color={colors.gray[600]} />
                  <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
                    Service Positions
                  </Text>

                  <PopoverTooltipPositionActions />

                  <Spacer />

                  <HStack space={1.5} alignItems="center">
                    <Box
                      rounded="full"
                      alignItems="center"
                      justifyContent="center"
                      w={5}
                      h={5}
                      borderWidth={1}
                      borderColor="white"
                      bg="warning.300"
                    >
                      <Octicons name="star" size={14} color="black" />
                    </Box>

                    <Text size="md" fontWeight={600} color="black">
                      Ops Leader
                    </Text>

                    <PopoverSelectOpsLeader
                      isDisabled={isDisabled}
                      positionSlots={positioning?.positionSlots}
                      onChange={updatePositionSlot}
                    />
                  </HStack>
                </HStack>
              )}

              {map(positioning?.positionSlots, (position, index) => {
                if (position.positionType === PositionTypeEnum.NonService) return undefined;

                return (
                  <Box key={`position-slot.${index}.${position.id}`} p={1.5} w="1/3">
                    <DroppablePositionSlot
                      key={`droppable-position-slot.${[
                        position.id,
                        position.positionId,
                        position.positionType,
                        isDisabled ? 'disabled' : 'enabled',
                        positioning?.subBlockTime,
                        position.assignee?.employeeId,
                      ]
                        .filter(Boolean)
                        .join('.')}`}
                      dayBlock={positioning?.dayBlock}
                      isDisabled={isDisabled}
                      position={position}
                      subBlockTime={positioning?.subBlockTime}
                      onChange={updatePositionSlot}
                      onDeleteAssignee={deleteAssignee}
                      onMoveTo={moveTo}
                      onEditPosition={isPrimary => modalPosition.setOpen({ isPrimary: isPrimary, positionSlotId: position.id! })}
                      onReplaceAssignee={replaceAssignee}
                      onSelect={() => modalSelect.setOpen(position)}
                    />
                  </Box>
                );
              })}
            </HStack>
          </Box>

          <Divider position="sticky" top={0} orientation="vertical" height={layout?.height ?? 0} w="1px" bg="gray.200" />

          <Box display={isLargeScreen ? 'none' : undefined} py={2.5} pl={2.5} pr={1.5} w="1/4">
            <PositioningPlanNonServices isDisabled={isDisabled} subBlockTime={positioning?.subBlockTime} />
          </Box>
        </HStack>
      </ScrollView>

      <Modal isOpen={modalSelect.isOpen} noPadding size="md" _content={{ maxWidth: 340 }} hideClose>
        {!!modalSelect.payload && (
          <PositioningSelectEmployee
            isDisabled={isDisabled}
            position={modalSelect.payload}
            onCancel={modalSelect.setHide}
            onSave={updatePositionSlot}
          />
        )}
      </Modal>

      <Modal
        isOpen={modalPosition.isOpen}
        noPadding
        size="xl"
        hideClose
        _content={{ maxWidth: 980, h: '80%' }}
        onClose={modalPosition.setHide}
      >
        {!!modalPosition.payload && (
          <PositioningSelectPosition
            positionSlots={positioning?.positionSlots}
            {...modalPosition.payload}
            onCancel={modalPosition.setHide}
            onSave={updatePositionSlot}
          />
        )}
      </Modal>
    </Fragment>
  );
};
