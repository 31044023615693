import { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, Box, HStack, VStack, View, useMediaQuery } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { first, isEmpty, last } from 'lodash';
import moment from 'moment';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatToLongDateOnly } from '@app/utils/date-formatter';
import { DailyGoalsForm } from '@app/features/ops-plan';
import { DailyPositionGoalsProvider, useGoalSettings } from '@app/features/ops-plan/context';
import { useGetGoalCategories } from '@app/features/ops-plan/hooks';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { DateTimePopover, ResourceLoader } from '@app/components/shared';

type DailyPositionalGoalsProps = {
  siteId: string;
  brandId?: string;
  marketId?: number;
  goalTypeIds?: number[];
};

export const DailyPositionalGoals = ({ siteId, brandId, marketId, goalTypeIds }: DailyPositionalGoalsProps) => {
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const [dayBlocks, setDayBlocks] = useState<DayBlock[]>([]);
  const { weeklyFocusSettings } = useGoalSettings();
  const [goalCategories] = useGetGoalCategories({ query: { brandId, marketId } });
  const week = siteTime.toStartEndOfWeek();
  const [isSmallScreen, isLargeScreen] = useMediaQuery([{ maxWidth: 1300 }, { maxWidth: 1600 }]);

  const startEndOfBlock = !isEmpty(dayBlocks)
    ? {
        startTime: first(dayBlocks)!.startTime,
        endTime: last(dayBlocks)!.endTime,
      }
    : undefined;

  const isPastDate = !startEndOfBlock || startEndOfBlock.endTime < siteTime.today();
  const isFutureDate = startEndOfBlock?.endTime && moment(week?.endDate).isSame(startEndOfBlock?.startTime, 'day');

  const handleChangeDate = date => {
    setDayBlocks(siteTime.toDayBlocks(date));
  };

  const handleChangeDay = (days: number) => () => {
    const dt = days === 0 ? siteTime.today() : new Date(dayBlocks[0].startTime.getTime());
    dt.setDate(dt.getDate() + days);
    setDayBlocks(siteTime.toDayBlocks(dt));
  };

  useEffect(() => {
    if (siteTime.siteId) setDayBlocks(siteTime.toDayBlocks());
  }, [siteTime.siteId]);

  return (
    <DailyPositionGoalsProvider dayBlocks={dayBlocks} siteId={siteId}>
      <View flex={{ xl: 1 }} h="full" w={{ md: 'full' }}>
        <ResourceLoader h="full" isLoading={goalCategories.isIdle || goalCategories.isLoading}>
          <VStack h="full">
            <HStack space={2} alignItems="center" justifyContent="space-between" py={2} px={5} bg="white" borderBottomWidth={1}>
              <Box flex={1}>
                <Text size="xl" fontWeight={700} lineHeight="xs" color="black" textTransform="uppercase">
                  {translate(weeklyFocusSettings.data?.positionGoalsHeader, weeklyFocusSettings.data?.positionGoalsHeaderTranslations)}
                </Text>
                <Text
                  size={!isSmallScreen && isLargeScreen ? 'sm' : 'md'}
                  fontWeight={500}
                  lineHeight="xs"
                  color="gray.700"
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {translate(weeklyFocusSettings.data?.positionGoalsCaption, weeklyFocusSettings.data?.positionGoalsCaptionTranslations)}
                </Text>
              </Box>

              <HStack space={2} alignItems="center" justifyContent="space-between">
                {!isEmpty(dayBlocks) && (
                  <>
                    <Button alternate outline w={10} onPress={handleChangeDay(-1)}>
                      <ArrowBackIcon size={4} color="gray.700" />
                    </Button>
                    <DateTimePopover selectedDate={dayBlocks[0].startTime} onChange={handleChangeDate} max={week.endDate}>
                      <Button alternate outline startIcon={<FeatherIcons name="calendar" size={16} color="gray.700" />}>
                        {formatToLongDateOnly(dayBlocks[0]?.startTime) ?? ''}
                      </Button>
                    </DateTimePopover>
                    <Button disabled={isFutureDate} alternate outline w={10} onPress={handleChangeDay(1)}>
                      <ArrowForwardIcon size={4} color="gray.700" />
                    </Button>
                    <Button alternate outline ml={1} onPress={handleChangeDay(0)}>
                      Today
                    </Button>
                  </>
                )}
              </HStack>
            </HStack>

            <View flex={1}>
              <DailyGoalsForm categories={goalCategories.data} disabled={isPastDate} goalTypeIds={goalTypeIds} />
            </View>
          </VStack>
        </ResourceLoader>
      </View>
    </DailyPositionGoalsProvider>
  );
};
