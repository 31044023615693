import { createElement, useEffect, useMemo, useRef, useState } from 'react';
import { Box, HStack, IIconProps, ScrollView, View, VStack } from 'native-base';
import { countBy, first, flatMap, map, some } from 'lodash';

import { ButtonGroup, Text } from '@pimm/base';
import { EquipmentInstancesDto, InstanceDetailsDto } from '@pimm/services/lib/store-equipment';
import { useGetDisplayGroups } from '../hooks/get-display-groups.hook';
import { BatteryEventIcon, DoorEventIcon, WifiOffIcon, TemperatureEventIcon } from '../icons';
import { CollapsibleGroup } from './collapsible-group';

type FilterEventType = 'All' | 'Temperature' | 'SignalStrength' | 'Door' | 'BatteryVoltage';

type EquipmentListProps = {
  displayGroups: ReturnType<typeof useGetDisplayGroups>;
  equipmentFocus?: EquipmentInstancesDto;
  isListView?: boolean;
  siteId?: string;
  onChangeEquipment?: (_equipment?: EquipmentInstancesDto) => void;
};

export const EquipmentList = ({ displayGroups, equipmentFocus, isListView, ...props }: EquipmentListProps) => {
  const scrollViewRef = useRef<any>();

  const [filterType, setFilterType] = useState<FilterEventType>('All');
  const [eventItems, setEventItems] =
    useState<{ label?: string; value: FilterEventType; icon?: React.ComponentType; iconProps?: IIconProps; count?: number }[]>();

  const hasEventPriority = (detail?: InstanceDetailsDto): boolean => {
    return !!detail && !!detail.IsOnline && detail.EventPriority?.toLowerCase() !== 'none';
  };

  const handleChangeTab = (_tab: string) => {
    let _equipment;
    if (_tab === 'list') {
      _equipment =
        equipmentFocus ??
        displayGroups.data?.reduce((_instance: EquipmentInstancesDto | undefined, _) => {
          return _instance ?? first(_.equipment);
        }, undefined);
    }
    handleSelectEquipment(_equipment);
  };

  const filteredDisplayGroups = useMemo(() => {
    if (!displayGroups.data) return [];
    if (filterType === 'All') return displayGroups.data;
    return displayGroups.data
      .map(displayGroup => ({
        displayGroup: displayGroup.displayGroup,
        equipment: displayGroup.equipment.filter(equipment => hasEventPriority(equipment.TemperatureMetrics?.[filterType])),
      }))
      .filter(displayGroup => displayGroup.equipment.length > 0);
  }, [displayGroups.data, filterType]);

  const handleSelectEquipment = (_equipment?: EquipmentInstancesDto) => {
    if (props.onChangeEquipment) props.onChangeEquipment(_equipment);
  };

  useEffect(() => {
    const equipmentList = flatMap(displayGroups.data, displayGroup => displayGroup.equipment);
    // Function to count instances with priority
    const countPriorityInstances = (instanceKey: FilterEventType) => {
      const countByPriority = countBy(equipmentList, _equipment => hasEventPriority(_equipment.TemperatureMetrics?.[instanceKey]));
      return countByPriority.true || 0;
    };
    setEventItems([
      { label: 'All', value: 'All' },
      { value: 'Temperature', icon: TemperatureEventIcon, count: countPriorityInstances('Temperature') },
      { value: 'SignalStrength', icon: WifiOffIcon, iconProps: { size: 'xs' }, count: countPriorityInstances('SignalStrength') },
      { value: 'Door', icon: DoorEventIcon, iconProps: { size: 'xs' }, count: countPriorityInstances('Door') },
      { value: 'BatteryVoltage', icon: BatteryEventIcon, count: countPriorityInstances('BatteryVoltage') },
    ]);
  }, [displayGroups.data]);

  useEffect(() => {
    let scrollToPosition = 0; // Initialize scroll position counter
    let tempCardHeight = 100;
    if (scrollViewRef.current && isListView) {
      // make sure the layout is rendered before performing the scroll
      scrollViewRef.current.measure(() => {
        //  find the item and break early when it is found
        const found = some(filteredDisplayGroups, group => {
          return some(group.equipment, equipment => {
            if (equipment.EquipmentName === equipmentFocus?.EquipmentName) {
              return true; // Return true to indicate the equipment is found
            }
            scrollToPosition++; // Increment the scroll position for each equipment
            return false;
          });
        });
        // Scroll only if the equipment is found
        if (found) {
          scrollViewRef.current?.scrollTo({ y: scrollToPosition * tempCardHeight, animated: true });
        }
      });
    }
  }, [scrollViewRef, isListView]);

  return (
    <VStack w="full" h="full">
      <Box flexDir={isListView ? 'column' : 'row'} justifyContent="space-between" py={2} px={3} borderBottomWidth={!isListView ? 1 : 0}>
        <ButtonGroup
          flex={!isListView ? 1 : undefined}
          mb={isListView ? 2 : 0}
          maxW="300px"
          value={isListView ? 'list' : 'grid'}
          onChange={handleChangeTab}
        >
          <ButtonGroup.Item flex={1} maxW="1/2" value="list">
            List View
          </ButtonGroup.Item>
          <ButtonGroup.Item flex={1} maxW="1/2" value="grid">
            Grid View
          </ButtonGroup.Item>
        </ButtonGroup>

        <ButtonGroup
          flex={!isListView ? 1 : undefined}
          maxWidth={!isListView ? 320 : undefined}
          value={filterType}
          onChange={setFilterType}
        >
          {map(eventItems, _event => {
            return (
              <ButtonGroup.Item
                key={_event.value}
                flex={1}
                value={_event.value}
                isDisabled={(_event.count ?? filteredDisplayGroups.length) <= 0}
              >
                <HStack space={1.5} alignItems="center">
                  {!!_event.icon && (
                    <Box rounded="full" alignItems="center" justifyContent="center" w={5} h={5} bg="gray.600">
                      {createElement<IIconProps>(_event.icon, { size: 22, color: 'white', ..._event.iconProps })}
                    </Box>
                  )}
                  <Text size="md" fontWeight={500} color="gray.800">
                    {_event.label ?? _event.count}
                  </Text>
                </HStack>
              </ButtonGroup.Item>
            );
          })}
        </ButtonGroup>
      </Box>
      <View flex={1}>
        <ScrollView px={3} w="full" h="full" ref={scrollViewRef}>
          <VStack>
            {map(filteredDisplayGroups, _group => {
              return (
                <CollapsibleGroup
                  key={_group.displayGroup}
                  instanceId={equipmentFocus?.TemperatureMetrics?.Temperature?.InstanceId}
                  items={_group.equipment}
                  orientation={isListView ? 'vertical' : undefined}
                  title={_group.displayGroup}
                  onPressItem={handleSelectEquipment}
                />
              );
            })}
          </VStack>
        </ScrollView>
      </View>
    </VStack>
  );
};
