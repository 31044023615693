import React, { useState, useMemo, useCallback } from 'react';
import { HStack, View, Box, Pressable, FlatList, Divider, useTheme } from 'native-base';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { isEmpty, map } from 'lodash';
import { Text } from '@pimm/base';

export type VirtualColumnProps<DataType> = {
  dataField: Extract<keyof DataType, string>;
  name: string;
  hidden?: boolean;
  renderTitle?: () => React.ReactNode | string;
  renderCell?: (props: { index: number; item: DataType }) => React.ReactNode;
};

type VirtualTableProps<DataType> = {
  bordered?: boolean;
  columns: VirtualColumnProps<DataType>[];
  data?: Array<DataType>;
  renderDataItem?: (dataItem: DataType, index: number) => React.ReactNode | string;
  renderEmptyData?: () => React.ReactNode;
  onItemPress?: (dataItem: DataType) => void;
} & React.ComponentProps<typeof View>;

interface VirtualTableOrderColumn {
  column: string;
  descending?: boolean;
}

function VirtualTable<T>({ bordered = false, columns, data, renderDataItem, onItemPress, ...props }: VirtualTableProps<T>) {
  const { colors } = useTheme();
  const dataFields = columns.filter(col => !col.hidden).map((col: VirtualColumnProps<T>) => col.dataField);
  const [orderBy, setOrderBy] = useState<VirtualTableOrderColumn>();
  const [selectedCell, setSelectedCell] = useState<number>();

  const sortedData = useMemo(() => {
    if (isEmpty(data) || !orderBy?.column) return data;
    // order by dataField
    return data!.slice().sort((a, b) => {
      const order = orderBy.descending ? -1 : 1;
      if (a[orderBy.column] < b[orderBy.column]) return -1 * order;
      if (a[orderBy.column] > b[orderBy.column]) return 1 * order;
      return 0;
    });
  }, [data, orderBy]);

  const handlePressOrderBy = (column, descending) => () => {
    setOrderBy({ column, descending });
  };

  const handleItemPress = (item: T, index: number) => {
    if (onItemPress) {
      onItemPress(item);
      setSelectedCell(index);
    }
  };

  return (
    <FlatList
      w="full"
      h="full"
      data={sortedData}
      borderColor="gray.200"
      borderWidth={!bordered ? 0 : props.borderWidth || 1}
      borderRadius="lg"
      initialNumToRender={25}
      stickyHeaderIndices={[0]}
      scrollEnabled={true}
      ItemSeparatorComponent={() => (bordered ? <Divider bgColor="gray.200" /> : undefined)}
      ListHeaderComponent={() => (
        <HStack w="full" bgColor="gray.200">
          {columns.map((col, index) => {
            const active = orderBy?.column === col.dataField;
            if (col.hidden) return null;
            return (
              <Pressable
                key={index}
                flex={1}
                borderLeftColor="gray.300"
                borderLeftWidth={bordered && index ? 1 : 0}
                onPress={handlePressOrderBy(col.dataField, active ? !orderBy?.descending : false)}
              >
                <HStack space={1} alignItems="center" py={1.5} px={3} minHeight={8}>
                  <Text color={active ? 'gray.900' : 'gray.600'} fontWeight={active ? 600 : 500}>
                    {col.name}
                  </Text>
                  <FontAwesomeIcon
                    name={!active ? 'unsorted' : orderBy?.descending ? 'sort-desc' : 'sort-asc'}
                    size={12}
                    color={active ? colors.gray[700] : colors.gray[400]}
                  />
                </HStack>
              </Pressable>
            );
          })}
        </HStack>
      )}
      ListEmptyComponent={props.renderEmptyData}
      renderItem={({ item, index }) => {
        return (
          <Pressable onPress={() => handleItemPress(item, index)} key={`${item} - ${index}`}>
            <HStack space={bordered ? '1px' : 0} minHeight={8} bgColor="gray.300">
              {map(dataFields, (field: keyof T, cell) => {
                const value = item[field] ? item[field] : '';
                return (
                  <Box
                    key={`table-row.${index}.column.${cell}`}
                    flex={1}
                    justifyContent="center"
                    py={1.5}
                    px={3}
                    bgColor={onItemPress && selectedCell === index ? 'gray.200' : 'white'}
                  >
                    {columns[cell].renderCell ? (
                      columns[cell].renderCell?.({ index, item })
                    ) : (
                      <Text color="black" size="md" fontWeight={400}>
                        {value as string}
                      </Text>
                    )}
                  </Box>
                );
              })}
            </HStack>
          </Pressable>
        );
      }}
    />
  );
}

export default VirtualTable;
