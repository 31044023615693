import { useEffect, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { Box, IBoxProps, View, useMediaQuery, useTheme } from 'native-base';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer, VictoryArea } from 'victory';
import { LinearGradient, Stop } from 'react-native-svg';
import { find, isEmpty, isNumber, isUndefined, range } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { LinkedInstanceChartDataDto, ThresholdsDto } from '@pimm/services/lib/store-equipment';
import { tempValue } from '@app/utils/string-formatter';
import { createMetricsets, getThresholdOffset, ThresholdColors } from '../_helper';
import { DoorEventIcon, WifiIcon } from '../icons';
import { EventLog } from './event-log-data';

type TemperatureChartProps = {
  _container?: IBoxProps;
  startDate?: Date;
  endDate?: Date;
  linkedInstances?: LinkedInstanceChartDataDto[];
  thresholds?: ThresholdsDto;
  eventLog?: EventLog;
};

export const TemperatureChart = ({ _container, startDate, endDate, linkedInstances, thresholds, eventLog }: TemperatureChartProps) => {
  const { colors } = useTheme();
  const [isTabletScreen] = useMediaQuery({ maxWidth: 1200 });
  const [layout, setLayout] = useState<LayoutRectangle>();
  const [tickValues, setTickValues] = useState<number[]>();
  const days = Math.round(moment(endDate).diff(moment(startDate), 'hours') / 24);

  const chart: {
    datasets: { x: number; y?: number | null }[];
    max: number;
    min: number;
    ticks: number[];
    thresholdTicks: number[];
    isDoorEnabled?: boolean;
    gradient: { offset: number; color: string }[];
    signalDatasets: ReturnType<typeof createMetricsets>;
    doorDatasets: ReturnType<typeof createMetricsets>;
  } = useMemo(() => {
    const linkedTemperature = find(linkedInstances, _ => !!_.Temperature);
    const linkedDoor = find(linkedInstances, _ => !!_.Door);

    const statistics = linkedTemperature?.Temperature?.Statistics;

    let datasets: { x: number; y?: number | null }[] = [];
    linkedTemperature?.Temperature?.Data?.forEach((_, i) => {
      const timestamp = new Date(_.Timestamp as string)!;
      let xValue = timestamp.getTime();
      let yValue: number | null = _.Value!;

      if (i >= 1) {
        const previousTimestamp = new Date(linkedTemperature?.Temperature?.Data![i - 1].Timestamp as string);

        const diffInMs = Math.abs(timestamp.getTime() - previousTimestamp.getTime());
        const diffInMinutes = diffInMs / (5 * 60 * 1000);

        if (diffInMinutes > 1) {
          xValue = timestamp.getTime() - 5 * 60 * 1000;
          yValue = null;
        }
      }

      datasets.push({ x: xValue, y: yValue });
    });

    const maxValue = Math.round(statistics?.Max ?? thresholds?.UpperRed ?? 0) + 1;
    const minValue = Math.round(statistics?.Min ?? thresholds?.LowerRed ?? 0) - 1;
    const numTicks = 6;

    let ticks: number[] = [];
    let interval = (maxValue - minValue) / (numTicks - 1);

    for (let i = 0; i < numTicks; i++) {
      ticks.push(Math.round(minValue + i * interval));
    }

    const thresholdTicks = [maxValue, thresholds?.UpperRed!, thresholds?.LowerRed!, minValue].filter(
      tick => typeof tick === 'number' && tick > minValue && tick <= maxValue,
    );

    const upperTemp = statistics?.Max!;
    const lowerTemp = statistics?.Min!;

    const upperRed = getThresholdOffset(thresholds?.UpperRed ?? 0, lowerTemp, upperTemp);
    const upperYellow = getThresholdOffset(thresholds?.UpperYellow ?? 0, lowerTemp, upperTemp);
    const upperGreen = getThresholdOffset(thresholds?.UpperGreen ?? 0, lowerTemp, upperTemp);
    const targetUpperBound = getThresholdOffset(thresholds?.TargetUpperBound ?? 0, lowerTemp, upperTemp);
    const targetLowerBound = getThresholdOffset(thresholds?.TargetLowerBound ?? 0, lowerTemp, upperTemp);
    const lowerGreen = getThresholdOffset(thresholds?.LowerGreen ?? 0, lowerTemp, upperTemp);
    const lowerYellow = getThresholdOffset(thresholds?.LowerYellow ?? 0, lowerTemp, upperTemp);
    const lowerRed = getThresholdOffset(thresholds?.LowerRed ?? 0, lowerTemp, upperTemp);

    const gradient = [
      { offset: 0, color: ThresholdColors.Red },
      { offset: upperRed, color: ThresholdColors.Red },
      { offset: upperRed, color: ThresholdColors.Yellow },
      { offset: upperYellow, color: ThresholdColors.Yellow },
      { offset: upperYellow, color: ThresholdColors.Green },
      { offset: upperGreen, color: ThresholdColors.Green },
      { offset: upperGreen, color: ThresholdColors.Blue },
      { offset: targetUpperBound, color: ThresholdColors.Blue },
      { offset: targetLowerBound, color: ThresholdColors.Blue },
      { offset: lowerGreen, color: ThresholdColors.Blue },
      { offset: lowerGreen, color: ThresholdColors.Green },
      { offset: lowerYellow, color: ThresholdColors.Green },
      { offset: lowerYellow, color: ThresholdColors.Yellow },
      { offset: lowerRed, color: ThresholdColors.Yellow },
      { offset: lowerRed, color: ThresholdColors.Red },
      { offset: 1, color: ThresholdColors.Red },
    ];

    const signalDatasets = createMetricsets(linkedTemperature?.RealTimeStatus?.Data);
    const doorDatasets = createMetricsets(linkedDoor?.Door?.Data);

    return {
      datasets: datasets ?? [],
      max: maxValue, // Math.ceil(Math.round(maxValue) / 5) * 5,
      min: minValue, // Math.ceil(Math.round(minValue) / 5) * 5 - 5,
      ticks: ticks,
      thresholdTicks: thresholdTicks ?? [],
      gradient: gradient,
      isDoorEnabled: !!linkedDoor,
      signalDatasets: signalDatasets,
      doorDatasets: doorDatasets,
    };
  }, [linkedInstances, thresholds, eventLog]);

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayout(nativeEvent.layout);
  };

  useEffect(() => {
    if (startDate && endDate) {
      // It's important to use hours diff to get the next day
      let ticks: number[] = [];

      if (days <= 1) {
        ticks = range(5).map(num => startDate.getTime() + num * 6 * 60 * 60 * 1000);
      } else if (days >= 90) {
        ticks = [
          startDate.getTime(),
          ...range(1, 10).map(i =>
            moment(startDate)
              .add(i * 10, 'days')
              .valueOf(),
          ),
        ];
      } else if (days >= 30) {
        ticks = [
          startDate.getTime(),
          moment(startDate).add(7, 'days').valueOf(),
          moment(startDate).add(15, 'days').valueOf(),
          moment(startDate).add(22, 'days').valueOf(),
          moment(startDate).add(30, 'days').valueOf(),
        ];
      } else {
        range(days + 1).forEach(d => ticks.push(moment(startDate).add(d, 'days').valueOf()));
      }
      setTickValues(ticks);
    }
  }, [startDate, endDate]);

  return (
    <View w="full">
      <Box
        w="full"
        h="full"
        height={{ base: chart.isDoorEnabled ? 305 : 280, xl: chart.isDoorEnabled ? 365 : 340 }}
        onLayout={handleChangeLayout}
        {..._container}
      >
        {isUndefined(linkedInstances) && (
          <Box h="full" w="full" alignItems="center" justifyContent="center">
            <Text size="lg" color="gray.600" fontWeight={400}>
              No Chart Data
            </Text>
          </Box>
        )}

        {/* Temperature Metrics */}
        {!!layout?.height && !isEmpty(chart.datasets) && (
          <VictoryChart
            width={layout?.width}
            height={isTabletScreen ? 260 : 320}
            containerComponent={
              <VictoryVoronoiContainer
                mouseFollowTooltips
                labels={({ datum }) => {
                  if (datum.y === chart.min || datum.y === chart.max) {
                    return ''; // Disable tooltip when hovering on VictoryArea
                  }
                  return `${moment(datum.x).format(days === 1 ? 'hh:mm A' : 'hh:mm A, MMM DD')}\nTemp: ${tempValue(datum.y)}`;
                }}
                labelComponent={
                  <VictoryTooltip
                    constrainToVisibleArea
                    flyoutStyle={{
                      stroke: ({ datum }) => {
                        let color: string = ThresholdColors.Blue;
                        if (isNumber(datum.y)) {
                          if (isNumber(thresholds?.UpperRed) && datum.y >= thresholds?.UpperRed) color = ThresholdColors.Red;
                          else if (isNumber(thresholds?.UpperYellow) && datum.y >= thresholds?.UpperYellow) color = ThresholdColors.Yellow;
                          else if (isNumber(thresholds?.UpperGreen) && datum.y >= thresholds?.UpperGreen) color = ThresholdColors.Green;
                          else if (isNumber(thresholds?.LowerRed) && datum.y <= thresholds?.LowerRed) color = ThresholdColors.Red;
                          else if (isNumber(thresholds?.LowerYellow) && datum.y <= thresholds?.LowerYellow) color = ThresholdColors.Yellow;
                          else if (isNumber(thresholds?.LowerGreen) && datum.y <= thresholds?.LowerGreen) color = ThresholdColors.Green;
                        }
                        return color;
                      },
                      strokeWidth: 1.2,
                      fill: 'white',
                    }}
                    flyoutPadding={{ top: 10, bottom: 10, left: 10, right: 10 }}
                    style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700, fontSize: 12, textAnchor: 'right', zIndex: 100 }}
                  />
                }
              />
            }
            padding={{ top: 30, left: isTabletScreen ? 40 : 50, bottom: 40, right: isTabletScreen ? 20 : 30 }}
            domainPadding={{ x: [0, 20] }}
            domain={{ x: [startDate?.getTime() ?? 0, endDate?.getTime() ?? 0], y: [chart.min, chart.max] }}
            theme={{
              chart: {},
              axis: {
                style: {
                  axisLabel: {
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 500,
                    fontSize: 12,
                  },
                  tickLabels: {
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 500,
                    fontSize: 12,
                  },
                },
              },
            }}
          >
            {typeof chart.max === 'number' && !!eventLog?.startTime && (
              <VictoryArea
                data={[
                  { x: new Date(eventLog?.startTime!).getTime(), y: chart.max },
                  { x: eventLog?.endTime ? new Date(eventLog.endTime!).getTime() : endDate?.getTime(), y: chart.max },
                ]}
                x="x"
                y="y"
                y0={chart.min}
                style={{
                  data: {
                    fill: colors.gray[200],
                  },
                }}
              />
            )}

            <LinearGradient id="thresholdColors" x2="0%" y1="0%" x1="0%" y2="100%">
              {chart.gradient.map((_gradient, i) => (
                <Stop key={`linear_${i}`} offset={_gradient.offset} stopColor={_gradient.color} />
              ))}
            </LinearGradient>

            <VictoryAxis
              style={{
                axis: { stroke: colors.gray[400], strokeWidth: 1 },
                grid: { stroke: 'transparent' },
                tickLabels: { padding: 15, fontFamily: "'Inter', sans-serif", fontWeight: 600 },
              }}
              offsetY={40}
              orientation="bottom"
              scale={{ x: 'time' }}
              tickFormat={(t, i) => moment(t).format(`${days <= 1 ? `${i === 0 || i === 4 ? 'MMM DD' : 'hh A'}` : 'MMM DD'}`)}
              tickValues={tickValues}
            />

            <VictoryAxis
              dependentAxis
              tickValues={chart.ticks}
              style={{
                axis: { stroke: colors.gray[400], strokeWidth: 1 },
                grid: { strokeWidth: 0 },
                ticks: { stroke: 'transparent', size: 1 },
                tickLabels: { stroke: 'transparent', padding: 10 },
              }}
            />

            <VictoryAxis
              dependentAxis
              tickValues={chart.thresholdTicks}
              orientation="right"
              style={{
                axis: { stroke: colors.gray[200], strokeWidth: 0 },
                ticks: { stroke: 'transparent', size: 0 },
                grid: {
                  stroke: ({ tick }) => {
                    if (tick === thresholds?.UpperRed || tick === thresholds?.LowerRed) return ThresholdColors.Red;
                    else if (tick === thresholds?.UpperYellow || tick === thresholds?.LowerYellow) return ThresholdColors.Yellow;
                    else if (tick === thresholds?.UpperGreen || tick === thresholds?.LowerGreen) return ThresholdColors.Green;
                    return 'transparent';
                  },
                  strokeWidth: 1,
                  strokeDasharray: ({ tick }) => {
                    let dash = '4,4';
                    if (tick > thresholds?.UpperRed! || tick < thresholds?.LowerRed!) dash = '0,0';
                    return dash;
                  },
                },
              }}
              tickFormat={t => ''}
            />

            <VictoryLine
              interpolation="linear"
              data={chart.datasets}
              style={{
                data: {
                  stroke: 'url(#thresholdColors)',
                  strokeWidth: `${days > 7 ? 0.7 : 1.2}`,
                },
                parent: { border: '1px solid #cc0000' },
              }}
            />
          </VictoryChart>
        )}

        {/* Signal Metrics */}
        <Box position="relative" justifyContent="center" mt={-2} width={layout?.width} py={1}>
          <Box position="absolute" left={5}>
            <WifiIcon size={4} color="black" />
          </Box>

          <VictoryChart
            width={layout?.width}
            height={12}
            scale={{ x: 'time' }}
            domain={{ x: [startDate?.getTime() ?? 0, endDate?.getTime() ?? 0] }}
            padding={{ top: 0, left: isTabletScreen ? 40 : 50, bottom: 0, right: isTabletScreen ? 20 : 30 }}
            style={{
              background: { fill: colors.gray[200] },
            }}
          >
            <VictoryAxis
              tickValues={[startDate?.getTime(), endDate?.getTime()]}
              style={{
                axis: { strokeWidth: 0 },
                grid: { strokeWidth: 0 },
                tickLabels: { display: 'none' },
              }}
            />

            {chart.signalDatasets.map((data, index) => {
              return (
                <VictoryLine
                  key={`signal-metrics-${data.value === 1 ? 'online' : 'offline'}-${index}`}
                  style={{
                    data: {
                      stroke: data.value === 1 ? colors.teal[500] : colors.teal[100],
                      strokeWidth: 25,
                    },
                  }}
                  data={[
                    { x: data.startTime, y: 0 },
                    { x: data.endTime, y: 0 },
                  ]}
                />
              );
            })}
          </VictoryChart>
        </Box>

        {/* Door Metrics */}
        {chart.isDoorEnabled && (
          <Box position="relative" justifyContent="center" width={layout?.width} py={1}>
            <Box position="absolute" left={5}>
              <DoorEventIcon size={4} color="black" />
            </Box>

            <VictoryChart
              width={layout?.width}
              height={12}
              scale={{ x: 'time' }}
              domain={{ x: [startDate?.getTime() ?? 0, endDate?.getTime() ?? 0] }}
              padding={{ top: 0, left: isTabletScreen ? 40 : 50, bottom: 0, right: isTabletScreen ? 20 : 30 }}
              style={{
                background: { fill: colors.gray[200] },
              }}
            >
              <VictoryAxis
                tickValues={[startDate?.getTime(), endDate?.getTime()]}
                style={{
                  axis: { strokeWidth: 0 },
                  grid: { strokeWidth: 0 },
                  tickLabels: { display: 'none' },
                }}
              />

              {chart.doorDatasets.map((data, index) => {
                return (
                  <VictoryLine
                    key={`door-metrics-${data.value === 1 ? 'closed' : 'open'}-${index}`}
                    style={{
                      data: {
                        stroke: data.value === 1 ? colors.fuchsia[100] : colors.fuchsia[500],
                        strokeWidth: 25,
                      },
                    }}
                    data={[
                      { x: data.startTime, y: 0 },
                      { x: data.endTime, y: 0 },
                    ]}
                  />
                );
              })}
            </VictoryChart>
          </Box>
        )}
      </Box>
    </View>
  );
};
